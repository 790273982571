@import '../../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  &:-webkit-overflow-scrolling {
    display: none;    
  }
}

.filteredSearches {
  display: flex;
  flex-direction: column;
  margin: auto;

  @media (--viewportMedium) {
    flex-direction: row;
    /* margin-top: 33px; */
  }

  @media (--viewportLarge) {
    flex-direction: column;
  }
}

.searchLink {
  width: 100%;
  /* margin-top: 25px; */

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

@media (--viewportMedium) {
  .searchLink {
    margin-top: 0;
    margin-right: 40px;
    margin-bottom: 0;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    /* box-shadow: var(--boxShadowSectionLocationHover); */
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(3 / 2 * 100%); /* 2:3 Aspect Ratio */
  }
}

.searchImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  border-radius: 4px;
  object-fit: contain;

  max-width: 100%;
  max-height: 100%;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.searchName {
  color: var(--marketplaceColor);
}

.ButtomUserNameList {
/* old design
  @apply --marketplaceDefaultFontStyles;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  z-index: 1;
  bottom: 70px;
  position: fixed;
  column-gap: 15px;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    bottom: 55px;
    width: 100%;
  }

  @media (--viewportLarge) {
    bottom: 55px;
  }
*/
display:none;
}

.TopUserNameList {
  /*old design
  @apply --marketplaceDefaultFontStyles;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  z-index: 1;
  position: fixed;
  column-gap: 15px;
  flex-wrap: wrap;
/*
  @media (--viewportMedium) {
    top: 45px;
    width: 100%;
  }

  @media (--viewportLarge) {
    top: 45px;
  }
  */
  display:none;
}

.profileLink {
  text-decoration: none;
  font-size: 8pt;
  line-height: 30px;
  color: var(--matterColorAnti);
  text-transform: uppercase;

  @media (--viewportMedium) {
    
  }

  &:hover {
    text-decoration: none;
  }
}

/* Mobile keyframes */

@keyframes groupDownwardAnimation1Mobile {
  0%   { transform: translateY(-1500px);}
  100%  { transform: translateY(500px);}
}

@keyframes groupDownwardAnimation2Mobile {
  0%   { transform: translateY(-1600px);}
  100%  { transform: translateY(500px);}
}

@keyframes groupDownwardAnimation3Mobile {
  0%   { transform: translateY(-1400px);}
  100%  { transform: translateY(600px);}
}

@keyframes groupDownwardAnimation4Mobile {
  0%   { transform: translateY(-1550px);}
  100%  { transform: translateY(450px);}
}

@keyframes groupDownwardAnimation5Mobile {
  0%   { transform: translateY(-1550px);}
  100%  { transform: translateY(550px);}
}

/* Desktop keyframes */


@keyframes groupUpwardAnimation1A {
  0%   { transform: translateY(0px);}
  100%  { transform: translateY(-3000px);}
}

@keyframes groupUpwardAnimation2A {
  0%   { transform: translateY(100px);}
  100%  { transform: translateY(-2300px);}
}

@keyframes groupUpwardAnimation2B {
  0%   { transform: translateY(100px);}
  100%  { transform: translateY(-2500px);}
}

@keyframes groupUpwardAnimation2Mobile {
  0%   { transform: translateY(100px);}
  100%  { transform: translateY(-2500px);}
}

@keyframes groupDownwardAnimation1A {
  0%   { transform: translateY(-3500px);}
  100%  { transform: translateY(0px);}
}

@keyframes groupDownwardAnimation1B {
  0%   { transform: translateY(-4000px);}
  100%  { transform: translateY(0px);}
}

@keyframes groupDownwardAnimation2A {
  0%   { transform: translateY(-3750px);}
  100%  { transform: translateY(-150px);}
}

@keyframes groupDownwardAnimation2B {
  0%   { transform: translateY(-4100px);}
  100%  { transform: translateY(-150px);}
}

@keyframes groupDownwardAnimation3 {
  0%   { transform: translateY(-3550px);}
  100%  { transform: translateY(-50px);}
}

.landingPageSections {
  display: block;
}

.filteredSearches {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.firstCol {
    display: block;
    align-items: center;
    
    max-width: 17%;
    width: 17%;
    height: 100%;

    animation-name: groupDownwardAnimation1Mobile;
    animation-duration: 25s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: normal;

    @media (--viewportMedium) {
      animation-name: groupDownwardAnimation1A;
      animation-duration: 35s;

      &:hover {
        animation-play-state: paused;
      }
    }

    @media (--viewportXLarge) {
      animation-name: groupDownwardAnimation1B;
    }
}

.secondCol {
    display: none;
    align-items: center;
    max-width: 17%;
    width: 17%;

    @media (--viewportMedium) {
      display: block;
      animation-name: groupUpwardAnimation1A;
      animation-duration: 35s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      animation-direction: normal;

      &:hover {
        animation-play-state: paused;
      }
    }
}

.secondColMobile {
  display: block;
  align-items: center;
  max-width: 17%;
  width: 17%;

  animation-name: groupDownwardAnimation2Mobile;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: normal;

  @media (--viewportMedium) {
    display: none;
  }
}

.thirdCol {
    display: block;
    align-items: center;
    max-width: 17%;
    width: 17%;

    animation-name: groupDownwardAnimation3Mobile;
    animation-duration: 25s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: ease-in-out;

    @media (--viewportMedium) {
      animation-name: groupDownwardAnimation2A;
      animation-duration: 35s;

      &:hover {
        animation-play-state: paused;
      }
    }

    @media (--viewportXLarge) {
      animation-name: groupDownwardAnimation2B;
    }
}

.fourthCol {
    display: none;
    align-items: center;
    max-width: 17%;
    width: 17%;

    @media (--viewportMedium) {
      display: block;
      animation-name: groupUpwardAnimation2A;
      animation-duration: 35s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-timing-function: ease-in-out;

      &:hover {
        animation-play-state: paused;
      }
    }

    @media (--viewportXLarge) {
      animation-name: groupUpwardAnimation2B;
    }
}

.fourthColMobile {
  display: block;
  align-items: center;
  max-width: 17%;
  width: 17%;

  animation-name: groupDownwardAnimation4Mobile;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: ease-in-out;

  @media (--viewportMedium) {
    display: none;
  }
}

.fifthCol {
    display: block;
    align-items: center;
    max-width: 17%;
    width: 17%;

    animation-name: groupDownwardAnimation5Mobile;
    animation-duration: 25s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: ease-in-out;

    @media (--viewportMedium) {
      animation-name: groupDownwardAnimation3;
      animation-duration: 35s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-timing-function: ease-in-out;

      &:hover {
        animation-play-state: paused;
      }
    }
}

.blockSpace {
  height: 100px;
}

.gifOneFifty {
  max-width: 100%;
  max-height: 100%;
  margin-top: 150px;
  margin-bottom: 0;
}

.gifHundred {
  max-width: 100%;
  max-height: 100%;
  margin-top: 0;
  margin-bottom: 100px;
}