@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */

.sections {
  margin: 0;
  padding-top: 1px;
  /* z-index: -1; */
  /* overflow: hidden; */
  /* height: 100%; */
}

.section {
  /* overflow: auto; */
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  /* margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px; */
  /* padding: 0 20px 0 20px; */
  margin: 0 40px 0 40px;
  overflow: visible;
  margin: auto;

  @media (--viewportMedium) {
    max-width: 100%;
    /* margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px; */
  }

  @media (--viewportLarge) {
    /* max-width: 1128px; */
    /* margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto; */
  }

  @media (--viewportXLarge) {
    /* max-width: 1056px; */
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 0vh;
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.seeMoreonHomePage {
  margin: auto;
  /* z-index: 1; */
  height: 25px;
  width: 300px;
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;

  @media (--viewportMedium) {
    bottom: 30px;
  }

  @media (--viewportLarge) {
    bottom: 30px;
  }

  &:active {
    text-decoration: none;
  }
}

/* logo */
.logoLinkLandingPage {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  height: 100%;
  &:hover {
    text-decoration: none;
  }
}

.logoLandingPage {
  /* If height is increased, change link paddings accordingly */
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
}

.LogoMenuLandingPage {  
  margin-top: 15px;
  margin-bottom: 15px;
  top: 15px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}